<template>
  <div class="serveDetail">
    <div class="detail">
      <div class="info">
        <div class="info-title">工单报修信息</div>
        <div class="label">
          <span>工单编号：</span>
          <span>{{ detailData.serviceNumber }}</span>
        </div>
        <div class="label">
          <span>工单类型：</span>
          <span>{{ detailData.workTypeName }}</span>
        </div>
        <div class="label">
          <span>设备名称：</span>
          <span>{{ detailData.equipmentName || "--" }}</span>
        </div>
        <div class="label">
          <span>损坏说明：</span>
          <span>{{ detailData.malfunctionDescription || "--" }}</span>
        </div>
        <div class="label">
          <span>发起时间：</span>
          <span>{{ detailData.createTime }}</span>
        </div>
      </div>
      <div class="content">
        <div class="steps">
          <div class="title">工单动态</div>
          <div class="steps-content">
            <custom-steps
              :list="stepList"
              :active="active"
              activeColor="rgba(0,0,0,0.85)"
              inactiveColor="rgba(0,0,0,0.5)"
            >
              <template slot-scope="dataIndex" slot="inactive-icon">
                <div class="status-icon">{{ dataIndex.index }}</div>
              </template>
              <template slot-scope="dataIndex" slot="active-icon">
                <div class="status-icon active-icon">{{ dataIndex.index }}</div>
              </template>
              <template slot="finish-icon">
                <img class="finish-icon" src="./img/success.png" alt="" />
              </template>
              <template slot-scope="{ row }">
                <p class="text" v-if="row.name">
                  {{ row.status }}-{{ row.name }}
                </p>
                <p class="text" v-else>{{ row.status }}</p>
                <p class="time">{{ row.time }}</p>
              </template>
            </custom-steps>
          </div>
        </div>
        <div class="address">
          <div class="title">报修地址</div>
          <div class="address-info">
            <div class="address-info-text">
              {{ detailData.spaceName }}
            </div>
            <div
              class="navigation"
              @click="
                handelNavigation(
                  detailData.start,
                  detailData.startName,
                  detailData.end,
                  detailData.spaceName
                )
              "
            >
              <span class="address-info-text text"> 路线 </span>
              <img src="./img/nav.png" alt="" />
            </div>
          </div>
        </div>
        <div class="fault-message">
          <div class="title">故障信息确认</div>
          <div class="form-item">
            <div class="form-item-title">专业类别</div>
            <div class="select" @click="handelClickMajorType">
              <div>
                {{ detailData.majorType ? detailData.majorType : "请选择" }}
              </div>
              <img src="./img/arrow.png" alt="" />
            </div>
            <div class="form-item-title">现场描述</div>
            <div class="textarea">
              <v-input
                :disabled="detailData.status == 3"
                type="textarea"
                v-model="detailData.sceneDescription"
                placeholder="输入备注故障信息"
              ></v-input>
            </div>
            <div class="form-item-title">上传照片</div>
            <div class="photo">
              <v-upload
                :imgUrl.sync="detailData.scenePic"
                :activityPicture.sync="detailData.scenePic"
                :disabled="detailData.status == 3"
                class="oItem-b_upload"
                :maxCount="6"
                ref="load"
              ></v-upload>
            </div>
          </div>
        </div>
        <div class="fault-message">
          <div class="title">处理完成信息确认</div>
          <div class="form-item">
            <div class="form-item-title">设备名称</div>
            <div class="select">
              <div>
                {{ detailData.equipmentName ? detailData.equipmentName : "" }}
              </div>
              <!-- <img src="./img/arrow.png" alt="" /> -->
            </div>
            <div class="form-item-title">描述</div>
            <div class="textarea">
              <v-input
                type="textarea"
                v-model="detailData.repairDescription"
                placeholder="输入备注故障信息"
              ></v-input>
            </div>
            <div class="form-item-title">上传照片</div>
            <div class="photo">
              <v-upload
                :imgUrl.sync="detailData.repairPic"
                :activityPicture.sync="detailData.repairPic"
                class="oItem-b_upload"
                :maxCount="6"
                ref="load"
              ></v-upload>
            </div>
            <div class="form-item-title">添加耗材</div>
            <div
              class="consumable-list"
              v-for="(item, index) in detailData.materialsUsed"
              :key="index"
              @click="handelDelConsumable(item)"
            >
              <div>{{ item.name }}</div>
              <div>{{ item.quantity }}{{ item.sku }}</div>
              <div
                v-if="detailData.status == 2"
                class="icon"
                @click="remove(index)"
              >
                <img src="./img/sub.png" alt="" />
              </div>
            </div>
            <div
              class="add-consumable"
              v-if="detailData.status == 2"
              @click="addConsumable"
            >
              <img src="./img/add.png" alt="" />
              <span>添加耗材</span>
            </div>

            <!-- <div class="form-item-title">维修人签字</div>
            <div class="sign">
              <img
                :src="detailData.repairerSign"
                alt=""
                v-if="!isShowsignCanvas"
              />
              <signCanvas
                v-else
                ref="esign"
                :width="614"
                :height="242"
                :isCrop="isCrop"
                :lineWidth="lineWidth"
                :lineColor="lineColor"
                :bgColor.sync="bgColor"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="detailData.status == 1 || detailData.status == 2">
      <div class="serve-btn" v-if="detailData.status == 1">
        <div class="btn">无法处理，申报上级</div>
        <div class="btn" @click="saveOrUpdateWorkInfo">接收工单</div>
      </div>
      <div class="finish-btn" v-if="detailData.status == 2">
        <div class="btn" @click="saveOrUpdateWorkInfo">处理完成</div>
      </div>
    </div>
    <v-picker
      :columns="list"
      :valueShow="isShowMajorType"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
    <v-picker
      :columns="deviceNameList"
      :valueShow="isShowDevice"
      :valueKey="eventLabel"
      @clickOverlay="clickOverlayDevice"
      @cancel="cancelPickDevice"
      @confirmPicker="confirmDevice"
    ></v-picker>
  </div>
</template>

<script>
// import signCanvas from "@/components/bussiness/signCanvas";
import {
  getWorkInfoById,
  saveOrUpdateWorkInfo,
  getDeviceList,
  uploadURL,
} from "./api.js";
import { base64toFile } from "@/utils/utils.js";
import customSteps from "./components/customSteps";
export default {
  name: "workOrderServeDetail",
  components: { customSteps },
  props: {},
  data() {
    return {
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      isShowMajorType: false,
      isShowDevice: false,
      eventKey: "label",
      eventLabel: "deviceName",
      list: [
        {
          value: "强电",
          label: "强电",
        },
        {
          value: "弱电",
          label: "弱电",
        },
        {
          value: "暖通",
          label: "暖通",
        },
        {
          value: "给排水",
          label: "给排水",
        },
        {
          value: "综合",
          label: "综合",
        },
        {
          value: "消防",
          label: "消防",
        },
        {
          value: "厨具",
          label: "厨具",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      deviceNameList: [],
      active: 0,
      detailData: {},
      formList: {
        sceneDescription: "",
      },
      stepList: [
        {
          status: "发起",
          name: "",
          time: "",
        },
        {
          status: "接受",
          name: "",
          time: "",
        },
        {
          status: "处理",
          name: "",
          time: "",
        },
        {
          status: "完成",
          name: "",
          time: "",
        },
      ],
      isShowsignCanvas: false,
    };
  },
  created() {
    if (sessionStorage.getItem("workOrder")) {
      this.detailData = JSON.parse(sessionStorage.getItem("workOrder"));
      this.isShowsignCanvas = !this.detailData.repairerSign;
      console.log("this.isShowsignCanvas----->", this.isShowsignCanvas);
      console.log("result----->", this.detailData);
    }
    this.getDeviceList();
  },
  mounted() {
    const { id, list } = this.$route.query;
    if (list != undefined) {
      const result = JSON.parse(decodeURIComponent(list));
      if (this.detailData?.materialsUsed?.length > 0) {
        this.detailData.materialsUsed = [
          ...this.detailData.materialsUsed,
          ...result,
        ];
      } else {
        this.detailData.materialsUsed = result;
      }
      // result.forEach((item1) => {
      //   const index = this.detailData.materialsUsed.findIndex(
      //     (item2) => item2.materialsId === item1.materialsId
      //   );
      //   if (index !== -1) {
      //     this.$set(
      //       this.detailData.materialsUsed[index],
      //       "quantity",
      //       item1.quantity
      //     );
      //   } else {
      //     this.detailData.materialsUsed.push(item1);
      //   }
      // });
      // this.detailData.materialsUsed = JSON.parse(decodeURIComponent(list));
      sessionStorage.removeItem("workOrder");
    }
    if (id != undefined) {
      this.getWorkInfoById(id);
    }
  },
  methods: {
    remove(index) {
      this.detailData.materialsUsed.splice(index, 1);
      this.$forceUpdate();
    },
    //handelDelConsumable
    handelDelConsumable(item) {},
    //添加耗材
    addConsumable() {
      sessionStorage.setItem("workOrder", JSON.stringify(this.detailData));
      this.$router.push({
        name: "workOrderConsumable",
        query: {
          workTypeId: this.detailData.workTypeId,
        },
      });
    },
    // 验证必填项
    valid() {
      const { majorType } = this.detailData;
      let message = undefined;
      if (majorType === "") {
        message = "请选择专业类别";
      }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
    // 验证必填项
    validatorMaterials() {
      const { materialsUsed } = this.detailData;
      let message = undefined;
      if (materialsUsed.length <= 0) {
        message = "请添加耗材！";
      }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
    /* signCanvas start */
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.uploadImg(base64toFile(res));
        })
        .catch((err) => {
          // alert(err); // 画布没有签字时会执行这里 'Not Signned'
          // if (err == "Not Signned") {
          //   this.$toast("未签名");
          // } else {
          this.$toast(err);
          // }
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadURL, formData);
      if (res.code == 200) {
        // const materialsUsed = this.detailData.materialsUsed.map((item) => {
        //   return {
        //     id: item.id,
        //     materialsId: item.materialsId,
        //     quantity: item.quantity,
        //     workId: item.workId,
        //   };
        // });
        this.detailData.repairerSign = res.data.link;
        const params = {
          optUser: this.$store.state.userId,
          serviceForm: {
            id: this.detailData.id,
            equipmentNumber: this.detailData.equipmentNumber,
            status: this.detailData.deviceStatus,
            repairDescription: this.detailData.repairDescription,
            repairPic: this.detailData.repairPic,
            repairerSign: this.detailData.repairerSign,
            materialsUseds: this.detailData.materialsUsed,
          },
        };
        this.submitUpdateWorkInfo(params);
      }
    },
    submitUpdateWorkInfo(params) {
      this.$axios.post(saveOrUpdateWorkInfo, params).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "maintenanceWorkOrder",
          });
        }
      });
    },
    //获取设备列表
    getDeviceList() {
      const params = {
        curPage: 1,
        pageSize: 100000,
      };

      this.$axios.get(getDeviceList, { params }).then((res) => {
        const { records } = res.data;
        this.deviceNameList = records;
      });
    },
    handelNavigation(start, startName, spaceId, serviceZone) {
      this.$router.push({
        name: "pathPlaning",
        query: {
          start,
          startName,
          spaceId,
          serviceZone,
          type: "worker",
        },
      });
    },
    handelClickMajorType() {
      this.isShowMajorType = true;
    },
    handelClickDevice() {
      this.isShowDevice = true;
    },
    confirm(node) {
      this.detailData.majorType = node.value;
      this.isShowMajorType = false;
    },
    confirmDevice(node) {
      this.detailData.equipmentNumber = node.deviceNum;
      this.detailData.deviceStatus = node.status;
      this.isShowDevice = false;
    },
    clickOverlay() {
      this.isShowMajorType = false;
    },
    clickOverlayDevice() {
      this.isShowDevice = false;
    },
    cancelPick() {
      this.isShowMajorType = false;
    },
    cancelPickDevice() {
      this.isShowDevice = false;
    },
    saveOrUpdateWorkInfo() {
      let params = {};
      if (this.detailData.status == 1) {
        params = {
          optUser: this.$store.state.userId,
          phone: this.$store.state.mobile,
          getForm: {
            id: this.detailData.id,
            sceneDescription: this.detailData.sceneDescription,
            scenePic: this.detailData.scenePic,
            majorType: this.detailData.majorType,
          },
        };
        if (!this.valid()) {
          return;
        }
        this.submitUpdateWorkInfo(params);
      } else {
        params = {
          optUser: this.$store.state.userId,
          serviceForm: {
            id: this.detailData.id,
            equipmentNumber: this.detailData.equipmentNumber,
            status: this.detailData.deviceStatus,
            repairDescription: this.detailData.repairDescription,
            repairPic: this.detailData.repairPic,
            // repairerSign: this.detailData.repairerSign,
            materialsUseds: this.detailData.materialsUsed,
          },
        };
        // 是否添加耗材
        if (!this.valid()) {
          return;
        }
        this.submitUpdateWorkInfo(params);
        // if (!this.isShowsignCanvas) {
        //   // const materialsUsed = this.detailData.materialsUsed.map((item) => {
        //   //   return {
        //   //     id: item.id,
        //   //     materialsId: item.materialsId,
        //   //     quantity: item.quantity,
        //   //     workId: item.workId,
        //   //   };
        //   // });

        // } else {
        //   // 未存在签名图片
        //   // this.handleGenerate();
        // }
      }
    },

    getWorkInfoById(id) {
      this.$axios
        .get(getWorkInfoById, {
          params: {
            id,
            phone: this.$store.state.mobile,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { status } = res.data;
            this.detailData = res.data;
            this.isShowsignCanvas = !this.detailData.repairerSign;
            console.log("this.isShowsignCanvas2----->", this.isShowsignCanvas);
            if (status == 1) {
              this.active = 1;
            } else if (status == 2) {
              this.active = 2;
            } else {
              this.active = 3;
            }
            this.stepList[0].time = res.data.createTime;
            this.stepList[1].name = res.data.repairName;
            this.stepList[1].time = res.data.repairTime;
            this.stepList[2].name = res.data.repairName;
            this.stepList[2].time = res.data.receiveTime;
            this.stepList[3].time = res.data.updateTime;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.serveDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-bottom: 60px;
  .detail {
    width: 100%;
    box-sizing: border-box;
    background: #f8f8f8;
    .info {
      background-image: url("./img/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 122%;
      padding: 84px 30px 22px 30px;
      box-sizing: border-box;
      .info-title {
        margin-bottom: 28px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .label {
        span {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
          &:last-child {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    .content {
      padding: 0 30px 24px 30px;
      width: 100%;
      box-sizing: border-box;
      .steps {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 542px;
        background: #ffffff;
        border-radius: 16px;
        padding: 28px 34px 44px 34px;
        box-sizing: border-box;

        .steps-content {
          flex: 1;
          .van-steps--vertical {
            padding: 0 0 0 45px;
            box-sizing: border-box;
          }
          .status-icon {
            width: 26px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            background: #efefef;
            border-radius: 50%;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #c1c1c1;
            &.active-icon {
              color: #ffffff;
              background: #fe4416;
            }
          }
          .finish-icon {
            width: 26px;
            height: 26px;
          }

          .text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 40px;
          }
          .time {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 34px;
          }
          ::v-deep .van-hairline {
            &::after {
              content: none;
            }
          }
        }
      }
      .address {
        width: 100%;
        min-height: 174px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 24px 32px 34px;
        margin-block: 22px;
        .address-info {
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .address-info-text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            &.text {
              color: #fe4416;
            }
          }
          .navigation {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            span {
              margin-right: 10px;
            }
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .fault-message {
        width: 100%;
        min-height: 856px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 34px 60px 34px;
        margin-bottom: 22px;
        .form-item {
          width: 100%;
          box-sizing: border-box;
          .form-item-title {
            margin-top: 44px;
            margin-bottom: 22px;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
          .sign {
            img {
              width: 614px;
              height: 242px;
            }
          }
          .consumable-list {
            box-sizing: border-box;
            padding: 14px 26px;
            width: 100%;
            min-height: 68px;
            background: #f3f3f3;
            border-radius: 10px;
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            div {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              flex: 2;
              text-align: center;
              img {
                width: 32px;
                height: 32px;
              }
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
            }
          }
          .add-consumable {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 204px;
            height: 68px;
            background: rgba(254, 68, 22, 0.05);
            border-radius: 6px;
            img {
              margin-right: 12px;
              width: 32px;
              height: 32px;
            }
            span {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fea05e;
            }
          }
          .select {
            width: 100%;
            height: 68px;
            background: #f3f3f3;
            border-radius: 10px;
            padding: 0 36px 0 26px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            > div {
              flex: 1;
              height: 100%;
              line-height: 68px;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            img {
              flex-shrink: 0;
              width: 19px;
              height: 13px;
            }
          }
          .textarea {
            height: 174px;
            background: #f3f3f3;
            border-radius: 10px;
            overflow: hidden;
            > div {
              height: 100%;
              width: 100%;
            }
            ::v-deep .van-cell {
              height: 100%;
              width: 100%;
              background: #f3f3f3;
              .van-field__body {
                width: 100%;
                height: 100%;
                textarea {
                  height: 100%;
                }
              }
              input.van-field__control::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
  }
  .title {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
  }

  .footer {
    width: 100%;
    background: #ffffff;

    box-sizing: border-box;
    padding: 48px 30px 48px 0;
    .serve-btn {
      display: flex;
      justify-content: flex-end;
      gap: 28px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .btn {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fe4416;
        line-height: 74px;
        text-align: center;
        &:first-child {
          width: 346px;
          height: 74px;
          background: #ffffff;
          border-radius: 74px;
          border: 2px solid #fe4416;
        }
        &:last-child {
          width: 226px;
          height: 74px;
          background: #fe4416;
          color: #ffffff;
          border-radius: 74px;
        }
      }
    }
    .finish-btn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .btn {
        width: 226px;
        height: 74px;
        background: #fe4416;

        border-radius: 74px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 74px;
        text-align: center;
      }
    }
  }
}
</style>
